import React from "react";

const WindowsApplication = () => {
  return (
    <div className="service-inner-container">
      <div className="img-sec">
        {/* <img src="https://cdn.dribbble.com/users/1025838/screenshots/6220885/devguy3.gif" /> */}
        <img
          src={require("../../../../assets/skills/gifs/windowsapplication.gif")}
          alt="windows application"
        />
      </div>
      <div className="common-header">Windows Application</div>
      <p className="intro-para">
        In the realm of Windows application development, we excel at crafting
        powerful and intuitive software solutions that streamline workflows and
        enhance productivity. Leveraging our expertise in Microsoft technologies
        and industry best practices, we specialize in developing robust
        applications that meet the unique needs of businesses and users alike.
        From concept to deployment, we guide you through every step of the
        development process, ensuring that your application is not only
        feature-rich but also user-friendly and scalable. Whether you need a
        custom desktop application, a productivity tool, or a specialized
        business solution, we have the skills and experience to bring your
        vision to life. With a focus on performance, security, and reliability,
        we deliver Windows applications that empower your organization to
        achieve its goals efficiently and effectively.
        <br />
        <br />
        <br />
        Let us be your trusted partner in harnessing the full potential of
        Windows technology to drive innovation and success in your business.
      </p>
      <div className="tech-stack">
        <div className="common-sub-header">Technology Stack</div>
      </div>
    </div>
  );
};

export default WindowsApplication;

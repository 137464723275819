import React from "react";

const SocialMarketing = () => {
  return (
    <div className="service-inner-container">
      <div className="img-sec">
        {/* <img src="https://i.pinimg.com/originals/95/f7/ab/95f7ab5f277ffda2476d55956f2ebd7a.gif" /> */}
        <img
          src={require("../../../../assets/skills/gifs/marketing.gif")}
          alt="marketing"
        />
      </div>
      <div className="common-header">Social Media Marketing</div>
      <p className="intro-para">
        In the dynamic world of social media marketing, we excel at crafting
        compelling narratives and fostering meaningful connections between
        brands and their audiences. With a strategic approach tailored to each
        platform's unique nuances, we harness the power of social media to drive
        engagement, increase brand awareness, and ultimately, generate results.
        From crafting attention-grabbing content to implementing targeted
        advertising campaigns, we leverage the latest tools and analytics to
        optimize performance and ensure maximum impact. With a finger on the
        pulse of trending topics and consumer behavior, we are adept at adapting
        our strategies to stay ahead of the curve and deliver measurable success
        for our clients.
        <br />
        <br />
        <br />
        Let us demonstrate how our expertise in social media marketing can
        elevate your brand's presence and drive growth in the digital landscape.
      </p>
      <div className="tech-stack">
        <div className="common-sub-header">Technology Stack</div>
      </div>
    </div>
  );
};

export default SocialMarketing;

import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div className="container services-container">
      <div className="common-header" id="services">
        Our Services
      </div>

      <div className="service-list">
        <Link to={"/services/web-development"}>
          <div className="list-item">
            <div className="center-h-v">
              <img src={require("../../../../assets/web-development.jpg")} />
            </div>
            <div className="service-header my-3">Web-development</div>

            <div className="brif-para">
              Crafting exceptional web experiences is our passion and expertise.
              As seasoned web developers, we thrive on turning concepts into
              captivating digital realities. With a keen eye for design and a
              commitment to cutting-edge technologies, we specialize in creating
              responsive, user-friendly websites that leave a lasting
              impression. Whether it's building dynamic web applications or
              designing sleek, modern interfaces, we prioritize both
              functionality and aesthetics to ensure a seamless user experience.
              Let our portfolio showcase the diverse range of projects we've
              undertaken, each one a testament to our dedication to excellence
              in web development.
            </div>
            <div className="discover-more text-center">
              Discover More <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </Link>
        <Link to={"/services/social-media-marketing"}>
          <div className="list-item">
            <div className="center-h-v">
              <img src={require("../../../../assets/social-media.webp")} />
            </div>
            <div className="service-header my-3">Social Media Marketing</div>
            <div className="brif-para">
              In the dynamic world of social media marketing, we excel at
              crafting compelling narratives and fostering meaningful
              connections between brands and their audiences. With a strategic
              approach tailored to each platform's unique nuances, we harness
              the power of social media to drive engagement, increase brand
              awareness, and ultimately, generate results. From crafting
              attention-grabbing content to implementing targeted advertising
              campaigns, we leverage the latest tools and analytics to optimize
              performance and ensure maximum impact. With a finger on the pulse
              of trending topics and consumer behavior, we are adept at adapting
              our strategies to stay ahead of the curve and deliver measurable
              success for our clients. Let us demonstrate how our expertise in
              social media marketing can elevate your brand's presence and drive
              growth in the digital landscape.
            </div>
            <div className="discover-more text-center">
              Discover More <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </Link>

        <Link to={"/services/seo"}>
          <div className="list-item">
            <div className="center-h-v">
              <img src={require("../../../../assets/seo.webp")} />
            </div>
            <div className="service-header my-3">SEO</div>
            <div className="brif-para">
              In the ever-evolving landscape of search engine optimization
              (SEO), we specialize in unlocking the full potential of your
              digital presence. Through a strategic combination of keyword
              research, content optimization, and technical enhancements, we
              ensure your website ranks prominently in search engine results
              pages (SERPs) and attracts qualified organic traffic. Our holistic
              approach to SEO goes beyond just improving rankings; we focus on
              enhancing user experience, increasing site accessibility, and
              maximizing conversion opportunities. With a keen understanding of
              search engine algorithms and industry best practices, we
              continuously refine our strategies to adapt to the latest trends
              and algorithm updates. Let us partner with you to elevate your
              online visibility, drive targeted traffic to your website, and
              achieve sustainable growth in the competitive digital landscape.
            </div>
            <div className="discover-more text-center">
              Discover More <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </Link>

        <Link to={"/services/ads"}>
          <div className="list-item">
            <div className="center-h-v">
              <img src={require("../../../../assets/ads.jpg")} />
            </div>
            <div className="service-header my-3">Ads</div>
            <div className="brif-para">
              In the fast-paced realm of online advertising, we specialize in
              crafting compelling campaigns that captivate audiences and drive
              tangible results. From eye-catching display ads to targeted
              pay-per-click (PPC) campaigns, we leverage advanced targeting
              techniques and creative strategies to maximize your ROI. Our
              data-driven approach ensures that every ad dollar is spent
              efficiently, targeting the right audience with the right message
              at the right time. Whether you're looking to increase brand
              awareness, drive website traffic, or boost conversions, we tailor
              our ad strategies to align with your specific goals and deliver
              measurable success. With a focus on continual optimization and
              performance tracking, we are committed to maximizing the impact of
              your advertising budget and helping your business thrive in the
              competitive digital landscape. Let us be your trusted partner in
              navigating the complex world of online advertising and achieving
              your marketing objectives.
            </div>
            <div className="discover-more text-center">
              Discover More <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </Link>
        <Link to={"/services/designing"}>
          <div className="list-item">
            <div className="center-h-v">
              <img src={require("../../../../assets/web-desiging.webp")} />
            </div>
            <div className="service-header my-3">Designing (UI/UX)</div>
            <div className="brif-para">
              In the realm of UI/UX design, we specialize in creating immersive
              digital experiences that seamlessly blend form and function. With
              a keen eye for aesthetics and a deep understanding of user
              psychology, we craft intuitive interfaces that delight users and
              drive engagement. From wireframing and prototyping to visual
              design and user testing, we employ a holistic approach to design
              that prioritizes user needs and business objectives. Our goal is
              to create interfaces that not only look stunning but also provide
              a seamless and enjoyable user journey. By conducting thorough
              research and iteration, we ensure that every design decision is
              backed by data and insights, resulting in interfaces that are both
              visually stunning and highly effective. Let us bring your digital
              vision to life with our expertise in UI/UX design, elevating your
              brand and enhancing user satisfaction in the digital landscape.
            </div>
            <div className="discover-more text-center">
              Discover More <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </Link>
        <Link to={"/services/windows-application"}>
          <div className="list-item">
            <div className="center-h-v">
              <img src={require("../../../../assets/windows-app.png")} />
            </div>
            <div className="service-header my-3">Windows Application</div>
            <div className="brif-para">
              In the realm of Windows application development, we excel at
              crafting powerful and intuitive software solutions that streamline
              workflows and enhance productivity. Leveraging our expertise in
              Microsoft technologies and industry best practices, we specialize
              in developing robust applications that meet the unique needs of
              businesses and users alike. From concept to deployment, we guide
              you through every step of the development process, ensuring that
              your application is not only feature-rich but also user-friendly
              and scalable. Whether you need a custom desktop application, a
              productivity tool, or a specialized business solution, we have the
              skills and experience to bring your vision to life. With a focus
              on performance, security, and reliability, we deliver Windows
              applications that empower your organization to achieve its goals
              efficiently and effectively. Let us be your trusted partner in
              harnessing the full potential of Windows technology to drive
              innovation and success in your business.
            </div>
            <div className="discover-more text-center">
              Discover More <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </Link>
        <Link to={"/services/web-hosting"}>
          <div className="list-item">
            <div className="center-h-v">
              <img src={require("../../../../assets/web-hosting.jpg")} />
            </div>
            <div className="service-header my-3">Web Hosting</div>
            <div className="brif-para">
              In the realm of web hosting, we specialize in providing reliable
              and scalable hosting solutions tailored to meet the diverse needs
              of businesses and individuals alike. Our state-of-the-art
              infrastructure and cutting-edge technologies ensure lightning-fast
              performance, rock-solid reliability, and ironclad security for
              your websites and applications. Whether you're launching a
              personal blog, an e-commerce store, or a corporate website, our
              flexible hosting plans and expert support team ensure that you
              have everything you need to succeed online. From shared hosting to
              dedicated servers, we offer a range of hosting options to
              accommodate any workload and budget. With a commitment to uptime,
              security, and customer satisfaction, we take the hassle out of web
              hosting so you can focus on what matters most—building and growing
              your online presence. Choose us as your hosting partner and
              experience the difference that reliable, high-performance hosting
              can make for your website or application.
            </div>
            <div className="discover-more text-center">
              Discover More <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </Link>
        <div className="list-item">
          <div className="align-items-center d-flex h-100 justify-content-center">
            <div className="display-6">We Will Add More...</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

import React from "react";

const MiddleBanner = () => {
  return (
    <>
      {/* <div className="circle-design">
        <img src={require("../../../../assets/design.png")} alt="design" />
      </div> */}
      <div class="banner-middle">
        <div className="center-h-v container gap-4">
          <div class="container inner-container">
            Welcome to the realm of the Curious and Explorer Web Developers
            Team,
            <br />
            Where innovation knows no bounds and creativity thrives! We are a
            dynamic group of forward-thinkers who are passionate about pushing
            the boundaries of web development. With insatiable curiosity and an
            adventurous spirit, we embark on a journey of discovery, constantly
            seeking new technologies, techniques, and trends to stay at the
            forefront of the digital landscape. Whether it's exploring emerging
            frameworks, diving into the latest design trends, or delving into
            the depths of browser capabilities, we embrace the challenge with
            enthusiasm and dedication. <br />
            <br />
            Join us on this exhilarating journey as we push the boundaries of
            what's possible in web development and shape the future of the
            digital world together.
          </div>

          <div className="right-sec">
            <img src={require("../../../../assets/pc.png")} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MiddleBanner;
